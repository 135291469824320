/*
Global text styles used in the design go here, also colors used for those text styles.
If those colors are used programmatically then add to Colors.ts using "var(--body1)" syntax instead of
redeclaring the color to prevent colors in CSS and JS getting out of sync.
*/

:root{
    /* general + greys + ui */
    --color-primary-500: #4221C6;
    --color-primary-600: #311994;
    --color-primary-700: #24126D;

    --color-secondary-500: #EDCF5A;
    --color-secondary-600: #E8C12B;
    --color-secondary-700: #E1B819;

    --color-grey-200: #F6F3FC;
    --color-grey-500: #D7D7D7;
    --color-grey-700: #4F4F4F;

    --color-white: #FFFFFF;
    --color-black: #000000;

    --color-error: #E8637E;
    --color-navigationbar: #FAFAFA;
    --color-background: #F2F2F6;

    /* text styles */
    --text-headings-dark-color: #23022E;
    --text-body1-color: #3F3C40;
    --text-body2-color: #717481;
    --text-headings-light-color: #FFFFFF;
    --text-body-light-color: #F8F8F8;
    --text-textfields-color: #6E6A77;

    /* status */
    --color-offen: #F4D65E;
    --color-gezahlt: #7FEC90;
    --color-dringend: #E8637E;
    --color-inkasso: #E4E3E9;
}

h1, .h1 {
    color: var(--text-headings-dark-color);
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
}

h2, .h2 {
    color: var(--text-headings-dark-color);
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
}

h3, .h3 {
    color: var(--text-headings-dark-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

a {
    color: var(--color-primary-500);
}

a:hover {
    color: var(--color-primary-600);
}

a:active {
    color: var(--color-primary-700);
}

.overline {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: var(--text-body2-color);
}

.bodyMBold {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: var(--text-headings-dark-color);
}

.bodyM {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-headings-dark-color);
}

p, .bodyS {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-body2-color);
}

.bodySBold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: var(--text-body2-color);
}

.bodyXS {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-body2-color);
}

.bodyXSBold {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: var(--text-body2-color);
}

.caption {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--text-headings-dark-color);
}