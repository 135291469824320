*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    display: flex;
    flex-direction: column;
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: var(--color-background);
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

/* via https://gwfh.mranftl.com/fonts */

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/open-sans-v34-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('./assets/fonts/open-sans-v34-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('./assets/fonts/open-sans-v34-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('./assets/fonts/open-sans-v34-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('./assets/fonts/open-sans-v34-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('./assets/fonts/open-sans-v34-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin */
@font-face {
font-family: 'Open Sans';
font-style: normal;
font-weight: 600;
src: url('./assets/fonts/open-sans-v34-latin-600.eot'); /* IE9 Compat Modes */
src: local(''),
    url('./assets/fonts/open-sans-v34-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/open-sans-v34-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/open-sans-v34-latin-600.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/open-sans-v34-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/open-sans-v34-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
src: url('./assets/fonts/open-sans-v34-latin-700.eot'); /* IE9 Compat Modes */
src: local(''),
    url('./assets/fonts/open-sans-v34-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('./assets/fonts/open-sans-v34-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./assets/fonts/open-sans-v34-latin-700.woff') format('woff'), /* Modern Browsers */
    url('./assets/fonts/open-sans-v34-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('./assets/fonts/open-sans-v34-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
